@media #{$mobile-above} {
	.services-elem {
		display: flex; }

	.services-elem__head {
		.services-elem__projects {
			display: none; } }

	.services-elem__content {
		width: calc(100% - 585px);
		padding-top: 3px;
		// max-width: 725px

		@media (max-width: 1449px) {
			width: 60%; }

		@media #{$tablet} {
			width: calc(100% - 318px);
			padding-top: 0; } }

	.services-elem__aside {
		width: 585px;
		padding-right: 30px;

		@media #{$tablet} {
			width: 318px;
			padding-right: 53px;

			.services-title {
				padding-right: 20px; } } }

	.services-elem__body {
		position: absolute;
		left: 0;
		bottom: 0; } }

.services-text__label,
.services-text__val {
	font-size: 20px;
	line-height: 32px; }

.services-text__label {
	color: #4D5460;
	font-weight: 500; }

.services-text__val {
	color: #9399A4;
	max-width: 480px;
	font-weight: 400; }

.services-text__group {
	&:not(:last-child) {
		margin-bottom: 20px; } }

.services-elem {
	padding: 55px 0 60px;
	position: relative;

	@media #{$tablet} {
		padding: 40px 0; }

	&:not(:first-child) {
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, .1);
			position: absolute;
			top: 0;
			left: 0; } }

	@media #{$tablet-above} {
		&:last-child {
			padding-bottom: 80px; } } }

.services-elem__aside {
	padding-bottom: 100px;

	@media #{$mobile-above} {
		position: relative; } }

.services-elem__content {
	font-size: 26px;
	line-height: 40px;

	@media #{$tablet} {
		font-size: 17px;
		line-height: 23px; }

	@media #{$mobile} {
		&:not(:last-child) {
			margin-bottom: 22px; } }

	li:not(:last-child) {
		margin-bottom: 20px; } }

.services-tabs {
	&:not(:last-child) {
		margin-bottom: 60px; } }

.services-tabs__head {
	display: flex;
	font-weight: 500;
	font-size: 24px;
	line-height: 170%;
	cursor: pointer;

	@media #{$tablet-above} {
		@media (max-width: 1499px) {
			font-size: 20px;
			line-height: normal; }

		@media (max-width: 1250px) {
			font-size: 16px; } }

	li {
		position: relative;

		&:not(:last-child) {
			margin-right: 35px;
			margin-bottom: 0;

			@media #{$tablet-above} {
				@media (max-width: 1499px) {
					margin-right: 20px; } } }

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background-color: #09101B;
			position: absolute;
			bottom: -16px;
			left: 0;
			opacity: 0; } }

	li:not(.active) {
		color: #9399A4; }

	li.active {
		&:before {
			opacity: 1; } } }

.services-title {
	font-size: 43px;
	line-height: 47px;
	color: #000;
	font-weight: bold;
	letter-spacing: 0.02em;

	@media #{$tablet} {
		font-size: 34px;
		line-height: 36px; }

	@media #{$mobile-above} {
		&:not(:last-child) {
			margin-bottom: 25px;

			@media #{$tablet} {
				margin-bottom: 20px; } } }

	a {
		&:hover {
			text-decoration: none; } } }

.services-elem__link {
	@media #{$mobile-above} {
		position: absolute;
		bottom: 0;
		left: 7px;

		@media #{$tablet} {
			max-width: 160px;
			left: 0; } } }

.services-link {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 16px;

	&:hover {
		color: $green_hover; }

	&__icon {
		img {
			vertical-align: bottom; }

		& + .services-link__text {
			margin-left: 16px; } } }

@keyframes animationgradienttitle {
	0% {
		background-position: 0 0; }

	100% {
		background-position: 100% 0; } }

.hover-gradient {
	position: relative;

	&__icon {
		color: #0E6BA7;

		svg {
			transition: transform .2s ease-out; } }

	&:hover {
		text-decoration: none;

		svg {
			transform: rotate(45deg); } } }

body:not(.ie-browser) {
	.hover-gradient {

		@media #{$tablet-above} {
			&:hover {
				background: rgba(10,106,166,1);
				background: -moz-linear-gradient(left, rgba(10,106,166,1) 0%, rgba(36,161,203,1) 11%, rgba(6,152,132,1) 25%, rgba(55,168,90,1) 39%, rgba(150,192,90,1) 50%, rgba(10,107,166,1) 61%, rgba(36,162,203,1) 71%, rgba(6,152,132,1) 82%, rgba(55,168,90,1) 92%, rgba(150,192,90,1) 100%);
				background: -webkit-gradient(left top, right top, color-stop(0%, rgba(10,106,166,1)), color-stop(11%, rgba(36,161,203,1)), color-stop(25%, rgba(6,152,132,1)), color-stop(39%, rgba(55,168,90,1)), color-stop(50%, rgba(150,192,90,1)), color-stop(61%, rgba(10,107,166,1)), color-stop(71%, rgba(36,162,203,1)), color-stop(82%, rgba(6,152,132,1)), color-stop(92%, rgba(55,168,90,1)), color-stop(100%, rgba(150,192,90,1)));
				background: -webkit-linear-gradient(left, rgba(10,106,166,1) 0%, rgba(36,161,203,1) 11%, rgba(6,152,132,1) 25%, rgba(55,168,90,1) 39%, rgba(150,192,90,1) 50%, rgba(10,107,166,1) 61%, rgba(36,162,203,1) 71%, rgba(6,152,132,1) 82%, rgba(55,168,90,1) 92%, rgba(150,192,90,1) 100%);
				background: -o-linear-gradient(left, rgba(10,106,166,1) 0%, rgba(36,161,203,1) 11%, rgba(6,152,132,1) 25%, rgba(55,168,90,1) 39%, rgba(150,192,90,1) 50%, rgba(10,107,166,1) 61%, rgba(36,162,203,1) 71%, rgba(6,152,132,1) 82%, rgba(55,168,90,1) 92%, rgba(150,192,90,1) 100%);
				background: -ms-linear-gradient(left, rgba(10,106,166,1) 0%, rgba(36,161,203,1) 11%, rgba(6,152,132,1) 25%, rgba(55,168,90,1) 39%, rgba(150,192,90,1) 50%, rgba(10,107,166,1) 61%, rgba(36,162,203,1) 71%, rgba(6,152,132,1) 82%, rgba(55,168,90,1) 92%, rgba(150,192,90,1) 100%);
				background: linear-gradient(to right, rgba(10,106,166,1) 0%, rgba(36,161,203,1) 11%, rgba(6,152,132,1) 25%, rgba(55,168,90,1) 39%, rgba(150,192,90,1) 50%, rgba(10,107,166,1) 61%, rgba(36,162,203,1) 71%, rgba(6,152,132,1) 82%, rgba(55,168,90,1) 92%, rgba(150,192,90,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a6aa6', endColorstr='#96c05a', GradientType=1 );
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 200%;
				animation: animationgradienttitle 3s linear forwards; } }

		@media #{$ie11} {
			&:hover {
				background: none;
				-webkit-background-clip: none;
				-webkit-text-fill-color: none;
				color: #0E6BA7; } } } }

@media #{$tablet} {
	.services-elem__head:not(:last-child) {
		margin-bottom: 40px; }

	.services-elem__aside {
		padding-bottom: 40px; }

	.services-tabs__head {
		overflow: auto;
		white-space: nowrap;
		margin-right: -32px;
		font-size: 17px;
		padding-bottom: 20px;

		&:after {
			content: '';
			display: block;
			min-width: 20px; } }

	.services-tabs:not(:last-child) {
		margin-bottom: 38px; }

	.services-tabs__head li:not(:last-child) {
		margin-right: 20px; } }

@media #{$mobile} {
	.services-title {
		font-size: 24px;
		line-height: 27px;

		br {
			display: none; }

		&:not(:last-child) {
			margin-bottom: 7px; }

		svg {
			width: 13px;
			height: 12px; } }

	.services-elem {
		padding: 26px 0; }

	.services-elem__head {
		margin-bottom: 24px;

		& + .services-elem__body {
			margin-top: -12px; } }

	.services-elem__content {
		font-size: 14px;
		line-height: 20px;

		li:not(:last-child) {
			margin-bottom: 12px; } }

	.services-elem__aside {
		padding-bottom: 19px; }

	.services-link__icon {
		img {
			width: 18px;
			height: 18px; } }

	.services-link__text {
		br {
			display: none; } }

	.services-elem__body {
		.services-elem__projects {
			display: none; } }

	.services-elem__projects {
		&:not(:last-child) {
			margin-bottom: 18px; } }

	.services-text__label,
	.services-text__val {
		font-size: 14px;
		line-height: 17px; }

	.services-text__group:not(:last-child) {
		margin-bottom: 14px; }

	.services-tabs__head {
		font-size: 14px;
		line-height: 170%;
		margin-left: -16px;
		margin-right: -16px;
		padding-bottom: 9px;

		&:before {
			content: '';
			display: block; }

		&:before,
		&:after {
			min-width: 16px; }

		li:not(:last-child) {
			margin-right: 24px;
			margin-bottom: 0; }

		li:before {
			bottom: -8px; } }

	.services-elem__head:not(:last-child) + .services-elem__body {
		margin-top: 0; }

	.services-elem__aside {
		padding-bottom: 0; }

	.services-tabs:not(:last-child) {
		margin-bottom: 21px; } }

.services-elem__projects {
	color: #bcbcbc;
	font-size: 14px;
	line-height: 40px;

	@media #{$mobile} {
		line-height: 17px; }

	span {
		font-weight: bold;

		@media #{$mobile-above} {
			font-size: 26px;

			@media #{$tablet} {
				font-size: 24px; } } } }

.services-box {
	position: relative;
	overflow: hidden;
	padding: 96px 0;
	color: #fff;
	background: conic-gradient(from -35.9deg at 100% 100%, #38A14C -55.57deg, #0F65A4 70.76deg, #28A1CE 158.75deg, #019681 243.12deg, #38A14C 304.43deg, #0F65A4 430.76deg);

	.services-title {
		color: inherit; }

	.services-elem__content {
		padding-top: 0; }

	@media #{$tablet} {
		padding: 50px 0;

		.services-elem__content {
			padding-top: 5px;

			li:not(:last-child) {
				margin-bottom: 11px; } } }

	@media #{$mobile} {
		padding: 38px 0;

		.services-elem__content {
			padding-top: 11px;

			li:not(:last-child) {
				margin-bottom: 12px; } } }

	&__icon {
		position: absolute;
		top: 28%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		text-align: center;

		@media #{$tablet} {
			display: none; }

		img {
			width: 100%; } }

	.services-elem {
		padding: 0; }

	.link {
		color: inherit;
		font-size: 26px;
		line-height: 40px;
		display: inline-block;

		@media #{$tablet} {
			font-size: 17px;
			line-height: 22px; }

		@media #{$mobile} {
			font-size: 14px;
			line-height: 20px;

			svg {
				width: 9px;
				height: 9px; } } } }

.link-arrow,
.link-arrow__icon {
	display: flex;
	align-items: center; }

.link-arrow__icon {
	overflow: hidden;

	svg {
		width: 30px;
		height: 15px;

		position: relative;
		top: -1px; } }

.link-arrow__box {
	display: inline-block;

	@media #{$tablet} {
		display: none; } }

.link-arrow__box {
	display: inline-block; }

.link-arrow {
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.04em;
	text-transform: uppercase;

	@media #{$tablet-above} {
		transition: color .2s ease-in-out;

		&:hover {
			color: #019681;

			.icon-arrow-right-link {
				transform: rotate(45deg); } } }

	&__icon {
		overflow: hidden;
		margin-left: 18px;

		&:not(:first-child) {
			margin-left: 9px; }

		svg {
			width: 19px;
			height: 10px;
			transition: transform .2s ease-in-out;

			&.icon-arrow-right-link {
				width: 12px;
				height: 13px; } } }

	.icon-link-right {
		position: relative;
		top: -1px; }

	@media #{$tablet} {
		font-size: 12px;
		line-height: 1; }


	@media #{$mobile} {
		font-size: 12px; } }


.page {
	.services-box {
		&:last-child {
			margin-bottom: -107px;

			@media #{$tablet} {
				margin-bottom: -80px; }

			@media #{$mobile} {
				margin-bottom: -46px; } } } }

.services-agency {
	@media #{$tablet-above} {
		.page .title.margin_middle {
			margin-bottom: 89px; } }

	@media #{$tablet-only} {
		.company-content {
			padding: 0;
			margin-right: -10px; }

		.page .title.title_size_middle {
			margin-bottom: 47px; } }

	@media #{$mobile} {
		.page {
			padding-top: 21px; }

		.title.title_size_middle {
			font-size: 24px;
			line-height: 29px;
			margin-bottom: 24px;
			max-width: 240px; }

		.page-head .company__paragraph {
			font-size: 12px;
			line-height: 16px; }

		.page-top:not(:last-child) {
			margin-bottom: 24px; } } }

@media #{$tablet-above} {
	.services-list {
		padding-top: 52px; }

	@media (max-width: 1400px) {
		.services-elem__content {
			font-size: 20px;
			line-height: normal; } }

	.services-elem__aside {
		@media (max-width: 1449px) {
			width: 40%;

			.services-title {
				font-size: 30px;
				line-height: normal; } } } }

.services {
	&:not(:last-child) {
		margin-bottom: 40px;

		@media #{$tablet} {
			margin-bottom: 70px; }

		@media #{$mobile} {
			margin-bottom: 20px; } } }

.services-link__desktop {
	@media #{$mobile} {
		display: none; } }

.services-link__mob {
	@media #{$mobile-above} {
		display: none; } }
