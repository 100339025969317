.partners-page {
	@media #{$tablet-above} {
		.company-cards {
			margin-top: 63px; }

		.projects {
			margin-top: 155px; } }

	.company-advantages__title {
		letter-spacing: 0.04em; }

	@media #{$tablet-only} {
		.title {
			font-size: 74px;
			line-height: 79px; }

		.company .title {
			margin-bottom: 50px; }

		.areas {
			padding-top: 87px;
			padding-left: 97px;
			padding-right: 97px; }

		.areas__text {
			font-size: 14px;
			line-height: 150%; }

		.areas__title {
			font-size: 26px;
			line-height: 32px;
			margin-bottom: 36px; }

		.company-cards {
			margin-top: 53px; }

		.company-cards__title {
			font-size: 42px;
			line-height: 46px; }

		.company-cards-col {
			margin-top: 0; }

		.company-cards__text {
			min-height: 42px; }

		.company-block__title {
			font-size: 26px;
			line-height: 32px; }

		.company-block {
			padding-top: 87px; }

		.company-advantages__title {
			padding-left: 75px;
			font-size: 14px;
			line-height: 24px;
			padding-bottom: 16px; }

		.company-advantages__item {
			margin-top: 33px; }

		.company-advantages__text {
			font-size: 14px;
			line-height: 20px; }

		.areas {
			padding-bottom: 88px; }

		.projects__title {
			font-size: 26px;
			line-height: 32px; }

		.projects {
			padding-left: 98px;
			padding-right: 98px;
			padding-top: 91px;
			padding-bottom: 100px; }

		.projects__icon {
			width: 38px;
			height: 38px; }

		.projects-card .projects__link {
			margin-top: 36px; }

		.projects__link a {
			font-size: 12px;
			line-height: 24px; } }

	@media #{$mobile} {
		.title {
			font-size: 35px;
			line-height: 38px;
			letter-spacing: -0.01em;
			margin-bottom: 32px; }

		.areas__title {
			font-size: 14px;
			line-height: 18px; }

		.areas__text {
			font-size: 12px;
			line-height: 16px; }

		.company-cards-col {
			width: calc(50% - 8px);
			padding: 0;
			margin-top: 0;

			&:nth-child(2n) {
				margin-left: 16px; } }

		.row {
			margin: 0; }

		.footer {
			.row {
				margin-left: -8px;
				margin-right: -8px; } }

		.company-cards__title {
			font-size: 30px;
			line-height: 36px; }

		.company-cards__item {
			padding: 11px 15px; }

		.company-block__title {
			font-size: 18px;
			line-height: 24px; }

		.company-advantages__item {
			margin-top: 19px; }

		.company-advantages__text {
			font-size: 12px;
			line-height: 16px; }

		.projects__title {
			font-size: 18px;
			line-height: 24px; }

		.projects__icon {
			width: 30px;
			height: 30px;

			.icon-more {
				width: 10px;
				height: 10px; } }

		.projects__link a {
			font-size: 10px;
			line-height: 24px; }

		.company {
			padding-top: 35px; }

		.company-cards {
			margin-top: 33px; }

		.company-cards__text {
			font-size: 12px;
			line-height: 16px;
			min-height: 30px; }

		.company-block {
			margin-top: 38px;
			padding-top: 33px; }

		.company-advantages__title {
			padding-left: 28px;
			padding-bottom: 10px;
			padding-right: 10px;
			font-size: 11px;
			line-height: 15px; }

		.areas {
			padding-bottom: 43px; }

		.projects {
			padding-top: 37px; }

		.projects-card .projects__link {
			margin-top: 22px; } } }
