.header-fixed {

	.header {
		position: fixed;

		&.animated {
			transform: translateY(-100%);
			transition: 0s;

			&.portfolio_nav:not(.is-shown) {
				transform: translateY(-160%); } }

		.header {
			&-bottom {
				border-bottom: #e9e9e9 1px solid;

				&__logo {
					display: block; } }

			&__slogan {
				display: none; }

			&.is-shown {
				transform: none; }

			.active-link {
				display: none; } } }

	&.menu-on {
		.header {
			background: #232B37; }


		@media #{$mobile-above} {
			.header {
				&.is-shown {
					transform: none; }

				.active-link {
					display: none; }

				&-bottom {
					border: none;

					&__logo {
						display: none; } }

				&__slogan {
					display: block; } } } } }

.header {
	background: #fff;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	transition: all ease-in-out .3s;
	transform: translateY(-100%);
	will-change: auto;
	opacity: 0;
	z-index: 30;

	&.portfolio_nav {
		@media #{$tablet-above} {
			&:before {
				content: '';
				height: 30vh;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0; } } }

	// &:hover
	// 	&:before
	// 		pointer-events: none

	&.animated {
		transform: translateY(0);
		opacity: 1;

		&.is-shown {
			transition: transform ease-out .5s;
			opacity: 1; } }

	&.is-shown {
		opacity: 1;
		visibility: visible !important;

		.portfolio-nav {
			transform: translateY(-100%); } }

	&.hide-nav {
		.portfolio-nav {
			@media #{$tablet-above} {
				transition: transform .3s ease-out;

				&.is-hover {
					transform: translateY(0); } } } }

	.portfolio-nav {
		@media #{$tablet} {
			transition: transform .2s ease-out;

			&.is-show {
				transition: transform .3s ease-out;
				transform: translateY(0); } } }

	.row {
		height: 96px;
		align-items: center;
		justify-content: space-between;

		& > * {
			max-width: none;
			width: auto; } }

	&-inner {
		background: #fff;
		position: relative;
		z-index: 1; }


	&-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 88px;
		border-bottom: #E9E9E9 1px solid;

		&__logo {
			width: 229px;
			height: 20px;
			margin-top: 5px;

			img {
				display: block; }

			@media #{$tablet} {
				width: 160px;
				height: 14px; }

			@media #{$mobile} {
				margin-top: 21px; } }

		&__nav {
			display: flex;
			align-items: center;
			margin-top: 10px;

			li {
				font-size: 16px;
				line-height: 1;
				font-weight: 600;
				text-transform: uppercase;
				margin-left: 32px;
				letter-spacing: 1px;

				@media (min-width: 1800px) {
					margin-left: 36px; }

				a {
					color: rgba(178, 178, 178, 1); }

				&.active a,
				&.active {
					color: rgba(9, 16, 27, 1); }

				a:hover {
					color: rgba(9, 16, 27, 1); } } } }

	&__slogan {
		display: inline-block;
		font-size: 14px;
		line-height: 1;
		font-weight: 500;
		background: linear-gradient(89.85deg, #0B6BA7 0.1%, #96C15B 99.87%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@media #{$ie11} {
			background: none;
			color: $green; } }

	&__lang {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 500;

		a:hover {
			color: $green_hover; } }

	&-bottom {
		&__row {
			display: flex; }

		&__logo {
			display: none;
			width: 229px;
			height: 20px;
			margin-top: 5px;

			img {
				display: block; }

			@media #{$tablet} {
				width: 160px;
				height: 14px; } }

		&-wrapper {
			align-items: center; }


		&__soc {
			margin-right: 24px;

			a {
				display: flex; }

			a + a {
				margin-right: 12px; }

			&-tg {
				&:hover {
					circle {
						fill: rgba(18, 102, 165, 1); }

					path {
						fill: rgba(255, 255, 255, 1); } }

				svg {
					width: 40px;
					height: 40px;

					circle, path {
						transition-duration: 0.15s; } } } }

		&__nav {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			li {

				&:not(:last-child) {
					margin-right: 24px;

					@media (min-width: 1800px) {
						margin-right: 26px; } }

				&.active a,
				&.active_clickable a {
					color: $green; }

				&.active a {
					pointer-events: none; } }

			a {
				font-size: 14px;
				line-height: 20px;
				font-weight: 500;
				white-space: nowrap;

				&:hover {
					color: $green_hover; } } }

		&__discuss {
			margin-left: auto;
			max-width: 150px;

			@media (min-width: 1800px) {
				max-width: 154px;
				width: 154px; } }

		& .but span {
			@media #{$tablet} {
				font-size: 14px;
				line-height: 20px;
				padding: 0 20px;
				white-space: nowrap; } } }

	.active-link {
		display: none; } }

.burger {
	position: absolute;
	top: 98px;
	right: 23px;
	width: 24px;
	height: 14px;
	padding: 6px 0;
	background: none;
	cursor: pointer;
	outline: none;
	box-sizing: border-box;
	z-index: 2;
	transition: opacity ease .3s;
	margin: 3px 10px 0 22px;

	&:hover {

		.lines {
			background: #09101B; } }

	.lines {
		position: relative;
		display: block;
		width: 100%;
		height: 2px;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		border-radius: 5px;
		background-color: #09101B;

		&:after, &:before {
			content: "";
			position: absolute;
			left: 0;
			top: -7px; }

		&:after {
			top: 7px;
			width: 50%;
			height: 2px;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			border-radius: 2px;
			background-color: #09101B; }

		&:before {
			width: 100%;
			height: 2px;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			border-radius: 3px;
			background-color: #000; } } }

.menu-on {
	.portfolio-nav {
		opacity: 0;
		pointer-events: none;
		transition: none; }

	.header-inner {
		background: none !important; }

	.burger {}

	.lines {
		background-color: transparent !important;

		&:before {
			transform: rotate(45deg) translate(6.5px, 4.4px);
			background-color: #fff; }

		&:after {
			transform: rotate(-45deg) translate(5px, -3px);
			background-color: #fff;
			width: 100%; } }

	.header-inner {
		.lines {
			&:before {
				background-color: #fff; }

			&:after {
				background-color: #fff; } } }

	.header {
		background: #232B37;

		.header-top {
			//padding-bottom: 21px
			border-bottom: 1px solid rgba(255, 255, 255, .1); }

		a {
			color: rgba(255, 255, 255, 0.3); }

		a:hover {
			color: white; }

		.active a {
			color: white; } } }


@media #{$tablet-above} {

	.header {

		&.animated {

			&.is-shown {
				transform: translateY(-88px); } }

		&.is-shown {
			transform: translateY(-88px); }

		&__bottom {

			.row {}
			height: 96px;
			align-items: center; } } }

@media #{$mobile-above} {

	.header {

		&-top__nav a:before {
			content: attr(data-title); } } }

@media #{$mobile} {
	.header {
		.burger:before {
			position: absolute;
			content: '';
			left: calc(50% - 24px);
			top: calc(50% - 24px);
			width: 100%;
			height: 100%;
			min-width: 48px;
			min-height: 48px; } } }


@media #{$middle-only} {

	.header {

		&-bottom {

			&__nav {
				flex-wrap: wrap;

				li:not(:last-child) {
					margin-right: 12px; } }

			&-col-lang {
				flex: 0 0 100px;
				width: 100px;
				max-width: 100px; }

			&-col-nav {
				margin-left: auto; } }

		&__lang {
			text-align: center; } }

	.promo {

		&__line {
			font-size: 75px;
			line-height: 0;
			text-transform: uppercase;
			letter-spacing: 0;
			font-weight: 500;
			margin-bottom: -25px; }

		&__mask {
			display: flex;
			align-items: center;
			height: 100px;
			overflow: hidden; }

		&__slider {
			margin: 0 10px;
			width: 75px;
			height: 75px;
			overflow: hidden; }

		.swiper-slide {
			max-height: 128px; }

		&__paragraph {
			position: absolute;
			right: 10px;
			bottom: -5px;
			font-size: 16px;
			line-height: 19px;
			max-width: 340px; }

		&-nav {
			margin-top: 140px;

			&__title {
				font-size: 20px; } } } }

@media screen and(min-width: 1400px)and(max-width: 1440px) {

	.header-bottom__nav {

		li:not(:last-child) {
			margin-right: 15px; }

		a {
			font-size: 16px; } } }

@media screen and(min-width: 1201px)and(max-width: 1340px) {

	.header {

		&-bottom {

			&__nav {
				flex-wrap: wrap;

				li:not(:last-child) {
					margin-right: 10px; }

				a {
					font-size: 13px; } } } } }

@media screen and(min-width: 1201px) {
	.burger {
		display: none; } }

@media screen and(min-width: 1025px)and(max-width: 1200px) {
	.header-fixed {

		.active-link {
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			letter-spacing: .7px;
			text-transform: uppercase;
			font-weight: 500;
			color: $green; } }

	.header {

		.container {
			padding: 0 40px; }

		&.animated {

			&.is-shown {
				transform: translateY(-72px); } }

		&.is-shown {
			transform: translateY(-72px); }

		&-top {
			height: 72px;

			&__nav {
				margin-top: 0;

				li {
					font-size: 12px;
					margin-left: 24px;
					letter-spacing: .7px; } } }

		&-bottom {
			padding-right: 55px;

			.row {
				height: 72px;
				align-items: center; }

			&__logo {
				margin-top: 0; }

			&-col {

				&-nav {
					display: none; }

				&-lang {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-left: auto;
					flex-grow: 1;
					max-width: calc(25% - 115px); } } }

		&__slogan {
			line-height: 1.3;
			margin-top: 0; }

		&__lang {
			text-align: right;
			padding-right: 15px;
			margin-left: auto; } } }

@media #{$tablet-only} {

	.header-fixed {

		.active-link {
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			letter-spacing: .7px;
			text-transform: uppercase;
			font-weight: 500;
			color: $green; } }

	.header {

		.container {
			padding: 0 40px; }

		&.animated {

			&.is-shown {
				transform: translateY(-72px); } }

		&.is-shown {
			transform: translateY(-72px); }

		&-top {
			height: 72px;

			&__nav {
				margin-top: 0;

				li {
					font-size: 12px;
					margin-left: 24px;
					letter-spacing: .7px; } } }

		&-bottom {
			padding-right: 55px;

			.row {
				height: 72px;
				align-items: center; }

			&__logo {
				margin-top: 0; }

			&-col {

				&-nav {
					display: none; }

				&-lang {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-left: auto;
					flex-grow: 1;
					max-width: calc(25% - 115px); } } }

		&__slogan {
			line-height: 1.3;
			margin-top: 0; }

		&__lang {
			text-align: right;
			padding-right: 15px;
			margin-left: auto; } }

	.burger {
		right: 30px; } }

@media #{$mobile} {
	.header-fixed:not(.menu-on) {

		.active-link {
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			letter-spacing: .7px;
			text-transform: uppercase;
			font-weight: 500;
			color: $green; }

		.header {
			border-bottom: #e9e9e9 1px solid;

			&-top {
				border-bottom: none;
				padding-bottom: 0;

				&__logo {
					width: 160px;
					height: 14px;
					max-height: 14px;
					background-size: contain;
					margin: 21px 0; }

				&__nav {

					li.active {
						display: none;
						position: fixed;
						top: 50px;
						left: 150px;
						width: calc(100% - 205px);
						z-index: 1;

						a {
							justify-content: center; } } } } } }

	.header-fixed {
		// .header
		//	 border-bottom: #e9e9e9 1px solid

		.header-top__logo {
			transition: none; }

		&.menu-on {
			.header {
				&.animated {
					&.is-shown {
						transform: none; } } } } }

	.header {

		&.animated {

			&.is-shown {
				transform: translateY(-48px); } }

		&.is-shown {
			transform: translateY(-48px); }

		&-top {
			flex-direction: column-reverse;
			align-items: flex-start;
			height: auto;
			padding-bottom: 21px;

			&__nav {
				flex: 0 0 100%;
				width: calc(100% + 32px);
				height: 40px;
				padding: 0 16px;
				background: #F2F2F2;
				margin: 0 -16px;
				justify-content: space-between;

				li {
					font-size: 12px;
					line-height: 1.75;
					margin-left: 0;
					letter-spacing: .7px;

					a {
						display: flex;
						align-items: center;
						height: 40px; } } } }

		&-bottom {
			display: none; } }

	.burger {
		position: absolute;
		right: 8px;
		top: 58px; }

	.menu-on {

		.header {

			&-top {

				&__nav {
					background: #09101B; } } } } }

.header-agency__slogan {
	display: none;

	.header__slogan {
		height: 56px;
		display: flex;
		align-items: center;
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		letter-spacing: 0.01em;
		font-feature-settings: 'pnum' on, 'lnum' on, 'case' on; } }
