.agency-page {
	&:not(.menu-on):not(.header-fixed) {
		.header-bottom__logo {
			opacity: 0;
			visibility: hidden; }

		@media #{$mobile} {
			.header-top__logo,
			.header-bottom__logo {
				display: none; } }

		@media #{$mobile} {
			.header-agency__slogan {
				display: block; } } }
	&:not(.menu-on) {
		.header-top {
			@media #{$mobile} {
				padding-bottom: 0 !important; } } }
	.title {
		text-align: left; }

	.best {
		.title {
			text-align: center; }

		@media #{$mobile-above} {
			.title {
				font-size: 55.5475px;
				line-height: 68px;

				@media #{$tablet} {
					font-size: 60px;
					line-height: 72px; } } } }

	.agency-solutions {
		@media #{$tablet-above} {
			padding: 103px 0 121px; } } }

.agency {
	&-section {
		&:not(:last-child) {
			margin-bottom: 58px;

			@media #{$tablet} {
				margin-bottom: 61px; }

			@media #{$mobile} {
				margin-bottom: 46px; } }

		& + .cases {
			margin-top: 111px;

			@media (min-width: 1800px) {
				margin-top: 58px; }

			@media #{$tablet-above} {
				@media (max-width: 1400px) {
					margin-top: 60px; } }

			@media #{$tablet} {
				margin-top: 50px; }

			@media #{$mobile} {
				margin-top: 46px; } } }

	&-head {
		padding-top: 150px;

		@media (min-width: 1800px) {
			padding-top: 72px; }

		@media #{$tablet-above} {
			@media (max-width: 1400px) {
				padding-top: 93px; } }

		@media #{$tablet-only} {
			padding-top: 68px;
			padding-left: 7px;
			padding-right: 7px; }

		@media #{$mobile} {
			padding-top: 49px; }

		&:not(:last-child) {
			margin-bottom: 113px;

			@media (min-width: 1800px) {
				margin-bottom: 70px; }

			@media #{$tablet-above} {
				@media (max-width: 1400px) {
					margin-bottom: 59px; } }

			@media #{$tablet} {
				margin-bottom: 59px; }

			@media #{$mobile} {
				margin-bottom: 43px; } } }

	&-head__logo {
		width: 100%;

		img {
			width: 100%;
			vertical-align: bottom; } }

	&-promo {
		position: relative;
		@media #{$tablet-above} {
			&.items_show {
				.agency-promo__text {
					opacity: 0;
					transition: opacity .1s ease; }

				.agency-promo__link {
					color: #CCCED1;

					a.is_active {
						color: #09101B;
						span {
							color: #38A14C; } } } } }

		@media #{$mobile-above} {
			display: flex;
			flex-direction: row-reverse; } }

	&__promo-animation {
		position: relative; }

	&-promo__text {
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.01em;
		transition: opacity 1s ease;

		@media #{$tablet-above} {
			padding-top: 10px;

			@media (max-width: 1400px) {
				font-size: 17.348px;
				line-height: 26px;
				max-width: 350px; } }

		@media #{$mobile-above} {
			width: 380px;
			margin-right: auto;

			@media #{$tablet} {
				width: 270px;
				font-size: 14px;
				line-height: 20px;
				padding-left: 10px;
				padding-top: 5px; }

			.agency-promo__links {
				width: calc(100% - 380px);
				max-width: 730px;
				margin-left: auto; } }

		@media #{$mobile} {
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.01em;
			max-width: 245px; } }

	&-promo__links {
		@media #{$mobile-above} {
			// flex: 0 0 50%
			width: 50%;
			padding-left: 5px;
			// max-width: 715px
			//
			// @media (max-width: 1300px)
			// 	max-width: 345px
			//
			// @media #{$tablet}
 }			// 	width: calc(100% - 360px)

		@media (min-width: 1401px) {
			flex: 0 0 59.4%;
			padding-top: 12px; }

		@media (min-width: 1800px) {
			flex: 0 0 49.8%; }

		@media (max-width: 1400px) {
			padding-left: 30px; }

		@media #{$tablet-only} {
			position: relative;
			left: 5px;
			padding-left: 5px; }

		@media #{$tablet-above } {
			@media (max-width: 1400px) {
				width: calc(100% - 500px);

 } }				// max-width: 615px

		@media #{$mobile} {
			padding-left: 0;

			&:not(:last-child) {
				margin-bottom: 24px; } } }

	&-promo__link {
		font-weight: 500;
		font-size: 48px;
		line-height: 58px;
		letter-spacing: -0.01em;
		text-transform: uppercase;
		color: #09101B;
		transition: all .2s ease-out;
		margin-bottom: 20px;
		span {
			margin-left: 12px;
			color: #CCCED1;
			transition: all .2s ease-out; }

		@media #{$tablet-above} {
			@media (max-width: 1400px) {
				font-size: 44px;
				line-height: 59px; } }

		a {
			transition: inherit; }

		@media (min-width: 1400px)and(max-width:1450px) {
			font-size: 46px; }

		@media (max-width: 1300px) {
			font-size: 20px;
			line-height: 32px;
			letter-spacing: 0.015em;
			margin-bottom: 0;
			span {
				margin-left: 5px; } }

		@media #{$tablet-only} {
			letter-spacing: 0;
			font-weight: 700; }

		@media #{$mobile} {
			font-size: 16px;
			line-height: 28px;
			letter-spacing: 0;
			margin-bottom: 0;
			font-weight: 700;
			span {
				margin-left: 5px; } }

		// @media #{$tablet-above}
		// 	color: #CCCED1

		// 	a:hover
 } }		// 		color: #09101B

.agency-services {
	padding-bottom: 155px;

	@media #{$tablet} {
		padding-bottom: 120px; }

	@media #{$mobile} {
		padding-bottom: 48px; }

	&:not(:first-child) {
		padding-top: 137px;

		@media #{$tablet} {
			padding-top: 107px; }

		@media #{$mobile} {
			padding-top: 53px; } }

	&__head {
		max-width: 600px;

		@media #{$tablet} {
			max-width: 280px; }

		&:not(:last-child) {
			margin-bottom: 147px;

			@media #{$tablet} {
				margin-bottom: 74px; }

			@media #{$mobile} {
				margin-bottom: 34px; } }

		.title {
			margin-bottom: 0; } }

	&__head-box {
		@media #{$tablet} {
			display: flex;
			width: 100%; } }

	&__body {
		position: relative;

		@media #{$tablet-above} {
			display: flex; } }

	&__card {
		@media #{$tablet-above} {
			width: calc(100% - 220px); }

		@media #{$middle-only} {
			width: calc(100%); } }

	&__button {
		@media #{$tablet-above} {
			width: 355px;
			text-align: right;
			padding-right: 91px;
			margin-top: -11px; }

		@media #{$mobile} {
			padding-top: 33px; }

		.but {
			width: auto;
			display: inline-block;

			@media #{$tablet-only} {
				position: absolute;
				bottom: calc(100% + 84px);
				right: 0; }

			span {
				padding-left: 46px;
				padding-right: 46px;

				text-transform: uppercase;

				@media #{$tablet} {
					padding-left: 34px;
					padding-right: 34px; }

				@media #{$mobile} {
					font-size: 12px;
					padding-left: 25px;
					padding-right: 25px; } } } }

	&__button {
		@media #{$tablet-only} {
			display: none; } } }



.link-arrow__box-tablet {

	@media #{$tablet-above} {
		display: none; }

	@media #{$tablet} {
		display: flex;
		align-items: flex-end;
		margin-left: auto;
		padding-bottom: 75px; }

	@media #{$mobile} {
		display: none; } }

.agency-solutions {
	background-color: rgba(242, 242, 242, .3);
	padding: 112px 0;
	overflow: hidden;

	@media #{$tablet} {
		padding: 64px 0 71px; }

	@media #{$mobile} {
		padding: 45px 0 50px; }

	& + .best {
		margin-top: 0; }

	& + .services {
		margin-top: 60px;

		@media #{$tablet} {
			margin-top: 11px; }

		@media #{$mobile} {
			margin-top: 15px; } } }

.agency-automation {
	padding: 150px 0 202px;

	@media #{$tablet} {
		padding: 62px 0 166px; }

	@media #{$mobile} {
		padding: 44px 0 50px; } }
