.header-bottom-wrapper {
	display: flex;
	gap: 56px;

	@media (min-width: 1800px) {
		gap: 58px;
	}

	@media (max-width: 1500px) {
		gap: 24px;
	}
	@media (max-width: 1250px) {
		gap: 4px;
	}
}

.header-bottom__nav {
	@media (max-width: 1250px) {
		margin-right: 4px;
	}
}

.header-bottom__soc {
	@media (max-width: 1250px) {
		margin-left: 4px;
	}
}
