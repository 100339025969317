.application {
  padding: 65px 0 107px;

  @media #{$tablet-above} {
    &.brigade-section {
      margin-top: 112px; } }

  @media #{$tablet} {
    padding-bottom: 55px; }

  @media #{$mobile} {
    padding-bottom: 45px; }

  .label-error {
    @media #{$mobile-above} {
      text-align: right; } }

  &__head {
    @media #{$tablet-above} {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 136px;

      .title.title_medium {
        margin-bottom: -16px; } } }

  &__title {
    text-align: left;
    letter-spacing: -2px;
    font-size: 108px;
    line-height: 112px;

    @media (max-width: 1300px) {
      font-size: 74px;
      line-height: 79px; }

    @media #{$mobile} {
      font-size: 35px;
      line-height: 38px; } }

  &-form {
    position: relative;

    section {

        &:not(:last-child) {
            margin-bottom: 53px;
            padding-bottom: 58px;
            border-bottom: $gray 1px solid; } } }

  &__fill {
    font-size: 34px;
    line-height: 40px; }

  &-input {
      position: relative; }

  &__label {
    font-size: 51px;
    line-height: 70px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 3px;

    @media (max-width: 1400px) {
      font-size: 42px;
      line-height: 55px; }

    span {
      color: #9399A4;
      text-transform: none;
      font-size: 32px; }

    i {
        font-style: normal; } }

  &__input {
    border: none;
    font-size: 51px;
    line-height: 1;
    height: auto;
    font-weight: 500;
    color: $black;
    padding: 0;
    text-transform: uppercase;
    // width: calc(100% - 350px)
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
    @include placeholder(#9399A4, 51px);
    transition: width .3s ease;

    @media (max-width: 1400px) {
      font-size: 42px;

      @include placeholder(#9399A4, 42px); } }

  &-input {
    &.is-error {
      input,
      textarea {
        color: #EB5757;

        &::placeholder {
          color: #EB5757; } }

      .application__input {
        width: calc(100% - 350px);

        @media #{$tablet} {
          width: calc(100% - 180px); }

        @media #{$mobile} {
          width: 100%; } } }

    &.is-success {
      input,
      textarea {
        color: #38A14C; }

      input:-internal-autofill-selected {
        -webkit-box-shadow: inset 0 0 0 50px #fff;
        -webkit-text-fill-color: #38A14C !important; } } }

  &__textarea {
    border: none;
    height: 72px;
    font-size: 51px;
    line-height: normal;
    font-weight: 500;
    color: $black;
    margin-top: 0;
    padding: 0;
    resize: none;
    text-transform: uppercase;
    @include placeholder(#9399A4, 51px);
    max-height: 1000px;
    margin-bottom: 15px;
    min-height: 70px;
    margin-bottom: -5px;

    @media (max-width: 1400px) {
      font-size: 42px;
      line-height: 55px;

      @include placeholder(#9399A4, 42px); } }

  .label-error {
      color: #EB5757;
      font-size: 16px;
      line-height: 1.5;
      width: 330px;
      position: absolute;
      right: 0;
      top: 20px; }

  &-type__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    li {
      margin: 0 20px 24px 0;

      // &.active

      //   label
      //     background: #000000
 }      //     color: #fff

    label:not(.checkbox-button) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-radius: 25px;
      padding: 13px 27px;
      font-size: 17.85px;
      font-weight: 500;
      background: #E9EBED;
      color: $black;
      transition: background ease .3s;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: #C9CCD1; } } }

  &-descr {

    .application__label {
      line-height: 78px; } }

  &-upload {
    margin-top: 50px; }

  &__agree {
    margin-top: 78px;
    color: #9399A4;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Roboto', 'Proxima', sans-serif;

    span {
        color: #000; }

    a {
        color: $green;

        &:hover {
            color: $green_hover; } } }


  &-submit {
    margin-top: 30px; }

  &-container {
    max-width: 944px;
    margin: 0 auto;

    @media (max-width: 1199px) {
      max-width: 540px; }

    .application-list {
      @media (min-width: 1200px) {
        margin-right: -244px;

        @media (max-width: 1500px) {
          margin-right: -100px; } }

      @media (min-width: 768px) {
        @media (max-width: 1199px) {
          margin-right: -124px; }

        @media (max-width: 1023px) {
           margin-right: -24px; } } } }

  &-list {
    @media #{$mobile-above} {
      display: flex;

      @media #{$tablet} {
        margin-right: -24px;

        .application-descr {
          margin-top: -16px; } } } }

  &-elem {
    position: relative;

    @media #{$mobile-above} {
      &:first-child {
        width: calc(100% - 288px);

        @media #{$tablet} {
          width: calc(100% - 188px);

          & + .application-elem {
            margin-left: auto; } } } }

    @media #{$mobile} {
      &:not(:last-child) {
        margin-bottom: 37px; }

      .but_round {
        margin-left: 0; } }

    &.has_line {
      @media #{$tablet-above} {
        &:before {
          content: "";
          position: absolute;
          right: calc(100% + 110px);
          top: 40px;
          display: block;
          width: 130px;
          height: 6px;
          background: radial-gradient(97.12% 256.02% at 100% 0%, #0F65A4 0%, #019681 41.68%, #38A14C 84.56%);

          @media (max-width: 1500px) {
            right: calc(100% + 40px);
            width: 60px; } } } }

    .application-submit {
      margin-top: 0; }

    .but_round {

      .go {

        @media #{$tablet} {
          width: 23px;
          height: 20px;
          margin: 0 0 0 11px; } } } }

  &-elem__title {
    font-weight: 500;
    font-size: 78px;
    line-height: 93px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on, 'case' on;

    @media (max-width: 1199px) {
      font-size: 42px;
      line-height: 47px; }

    @media #{$mobile} {
      font-size: 26px;
      line-height: 31px; } }

  .but_round {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    position: relative;
    width: 244px;
    height: 244px;
    margin: 0 auto;
    background: transparent;
    cursor: pointer;

    @media #{$tablet-above} {
      &:hover {
        .go:after,
        .ring:after {
          opacity: 1; } } }

    @media #{$tablet} {
      width: 124px;
      height: 124px; }

    @media #{$mobile} {
      width: 115px;
      height: 115px; }

    &[disabled] {
      filter: grayscale(1);
      pointer-events: none; }

    .go {
      display: block;
      width: 46px;
      height: 40px;
      // margin: -8px 0 0 23px
      margin: 0px 0 0 13px;
      background: url('../images/svg/go.svg') no-repeat center;
      background-size: contain;
      z-index: 2;
      position: relative;

      &:after {
        background-image: url('../images/svg/go-hover.svg'); } }

    .ring {
      display: block;
      @include abscenter;
      width: 100%;
      height: 100%;
      background: url('../images/svg/ring.svg') no-repeat center;
      background-size: contain;
      z-index: 1;

      &:after {
        background-image: url('../images/svg/ring-hover.svg'); } }

    .go,
    .ring {
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: opacity .2s ease-out;
        opacity: 0; } }

    &:not([disabled]) {
        .ring {
            animation: rotating 35s linear infinite; } } } }

sup.required {
  font-size: 31px;
  line-height: 1px;
  color: #9399A4;
  position: relative;
  top: -21px;
  left: 11px;
  vertical-align: baseline;

  @media #{$tablet} {
    font-size: 21px;
    top: -17px; }

  @media #{$mobile} {
    font-size: 15px;
    left: 6px;
    top: -2px; } }

@keyframes rotating {

  from {
    transform: rotate(0deg); }

  to {
    transform: rotate(360deg); } }

@media #{$tablet-above} {

    .application {

        &-name {
            margin-top: 98px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 212px;
                display: block;
                width: 130px;
                height: 6px;
                background: radial-gradient(97.12% 256.02% at 100% 0%, #0F65A4 0%, #019681 41.68%, #38A14C 84.56%); } } } }

@media #{$tablet-only} {

    .application {
        // max-width: 680px
        margin: 0 auto;
        padding: 23px 40px 80px;

        &:not(.brigade-section) {
          .container {
            padding: 0; } }

        &__title {
            font-size: 74px;
            line-height: 79px;
            letter-spacing: -2px;
            margin: 0 0 34px; }

        section {

            &:not(:last-child) {
                margin-bottom: 44px;
                padding-bottom: 35px; } }

        &__fill {
            font-size: 20px;
            line-height: 25px; }

        &-name {
            margin-top: 68px; }

        &__label {
            font-size: 42px;
            line-height: 55px;
            margin-bottom: 7px;

            span {
                font-size: 20px; } }

        &__input {
            font-size: 42px;
            line-height: 55px;
            margin-top: 0px;
            // width: calc(100% - 180px)
            @include placeholder(#9399A4, 42px); }

        &__textarea {
            height: 42px;
            font-size: 35px;
            line-height: 49px;
            @include placeholder(#9399A4, 42px); }

        .label-error {
          font-size: 12px;
          line-height: 18px;
          width: 250px;
          top: 8px; }

        &-type__list {
            margin-top: 12px; }

        li {
          margin: 0 10px 10px 0; }

        // label
        //   height: 45px
        //   padding: 10px 20px
        //   font-size: 14px

        &-descr {

            .application__label {
              line-height: 42px; } }

        &-upload {
            margin-top: 30px; }

        .fieldset-file__start {
            padding: 0; }

        &__agree {
            margin-top: 55px; }

        &-descr-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end; }

        &-submit {
            margin: 0 0 5px auto; }

        .but_round {
            width: 124px;
            height: 124px;
            margin: 0 auto;

            .go {
                display: block;
                width: 24px;
                height: 20px;
                margin: 0px 0 0 13px; } } } }

@media (max-width: 1023px) {
  .application {
    section {
      &:not(:last-child) {
        margin-bottom: 38px;
        padding-bottom: 36px; } }

    &-name {
        margin-top: 68px; }

    &__label {
        font-size: 35px;
        line-height: 49px;
        margin-bottom: 0;

        span {
          font-size: 20px; } }

    &__input {
        font-size: 35px;
        line-height: 49px;
        margin-top: 0px;
        // width: calc(100% - 180px)
        @include placeholder(#9399A4, 35px); }

    &__textarea {
        height: 60px;
        min-height: 60px;
        font-size: 35px;
        line-height: 49px;
        @include placeholder(#9399A4, 35px); }

    .label-error {
      font-size: 12px;
      line-height: 18px;
      width: 250px;
      top: 8px; } } }


@media #{$mobile} {

    .application {
        padding: 27px 0 57px;

        &__title {
            font-size: 35px;
            line-height: 38px;
            margin: 0 0 15px;
            letter-spacing: 0px; }

        section {

            &:not(:last-child) {
                margin-bottom: 20px;
                padding-bottom: 15px; } }

        &__fill {
            font-size: 14px;
            line-height: 18px; }

        &-name {
            margin-top: 30px; }

        &__label {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.5px;
            margin-bottom: -5px;

            span {
                font-size: 12px; }

            i {
                display: none; } }

        &__input {
            font-size: 16px;
            line-height: 22px;
            width: 100%;
            @include placeholder(#9399A4, 16px); }

        &__textarea {
            height: 30px;
            min-height: 30px;
            font-size: 16px;
            line-height: 22px;
            // margin-top: -10px
            line-height: 1;
            @include placeholder(#9399A4, 16px);

            & + .label-error {
              margin-top: -13px; } }

        &-type__list {
            margin-top: 25px; }

        li {
          margin: 0 8px 8px 0; }

        // label
        //   height: 38px
        //   padding: 6px 16px
        //   font-size: 12px

        &-descr {

            .application__label {
              line-height: normal;
              margin-bottom: 5px; } }

        &-upload {
            margin-top: 5px; }

        .fieldset-file__start {
            padding: 0; }

        &__agree {
            font-size: 12px;
            line-height: 1.5;
            margin-top: 34px; }

        &-submit {
            margin: 0; }

        .but_round {
            width: 115px;
            height: 115px;
            margin: 5px 0 0;

            .go {
                display: block;
                width: 24px;
                height: 20px;
                margin: 0 0 0 13px; } }

        .label-error {
            position: relative;
            right: auto;
            top: 0;
            font-size: 12px;
            line-height: 142%;
            width: auto;
            display: block; } } }

.hidden_input {
  opacity: 0;
  visibility: hidden;
  position: absolute; }

.checkbox-button {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;

  @media #{$tablet} {
    height: 45px; }

  @media #{$mobile} {
    height: 38px; }

  @media #{$tablet-above} {
    &:hover {
      .checkbox-button__title {
        background: #C9CCD1; } } }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .checkbox-button__title {
      color: #fff;
      background-color: $black;
      padding-right: 40px;

      @media #{$tablet} {
        padding-right: 35px; }

      @media #{$mobile} {
        padding-right: 30px; }

      &:after {
        opacity: 1; } } }

  &__title {
    border-radius: 25px;
    padding: 13px 27px;
    font-size: 17.85px;
    font-weight: 500;
    background: #E9EBED;
    color: $black;
    transition: all ease .3s;
    position: relative;

    @media #{$tablet} {
      padding: 10px 20px;
      font-size: 14px; }

    @media #{$mobile} {
      padding: 10.5px 16px;
      font-size: 12px; }

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
      background: url('../images/svg/x.svg') no-repeat center;
      background-size: contain;
      opacity: 0;

      @media #{$tablet} {
        right: 19px; }

      @media #{$mobile} {
        right: 14px; } } } }
