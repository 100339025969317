.vacancy-page {
	.article-content {
		@media #{$tablet-above} {
			margin-top: 79px;

			ul:not([class]) li:not(:last-child) {
				margin-bottom: 16px; } } } }

.vacancy-response {
	@media #{$tablet-above} {
		.application-descr-row {
			padding-left: 8px;

			.application__label {
				padding-top: 8px; } }

		.application__label {
			& + .application-upload {
				margin-top: 81px; } }

		.application__agree {
			margin-top: 99px; } }

	@media (min-width: 1400px) {
		.application-submit {
			position: relative;
			left: 45px;
			margin-top: 108px; } }

	@media #{$tablet-only} {
		.application {
			max-width: 100%;

			section:not(:last-child) {
				padding-bottom: 43px; } }

		.application-descr-row {
			.application__label {
				padding-top: 4px; } }

		.application__label {
			& + .application-upload {
				margin-top: 53px; } }

		.application-form-content {
			padding: 0 42px; }

		.application__label {
			letter-spacing: 0; }

		.fieldset-file__title {
			font-size: 12px;
			line-height: 24px;
			letter-spacing: 0.04em; }

		.application__agree {
			margin-top: 37px; }

		.application-submit {
			margin-right: 5px; } }

	@media #{$mobile} {
		.application {
			section:not(:last-child) {
				padding-bottom: 29px; } }

		.application-descr-row {
			.application__label {
				&:first-child {
					margin-top: -3px; } } }

		.fieldset-file__title {
			font-size: 10px;
			letter-spacing: 0.04em; }

		.application__label {
			& + .application-upload {
				margin-top: 19px; } }

		.application-submit {
			padding-top: 6px; }

		.application__agree br {
			display: none; }

		.application__fill {
			font-size: 12px;
			line-height: 16px; }

		.application__title {
			margin-bottom: 18px; } } }
