.main-news {
    margin-top: 160px;
    padding-bottom: 162px;

    &-inside {
        margin-top: 65px;

        .title {
            margin-bottom: 55px; } }

    &__title.title {
        text-align: left; }

    .tabs__list {
        margin-bottom: 60px; }

    &__hover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        background-color: #232B37;
        padding: 25px 32px;
        z-index: 1;
        transform: translateY(110%);
        transition: transform .3s ease-out;

        .main-news__category,
        .main-news__preview {
            padding: 0; }

        .main-news__category {
            color: #9399A4;
            margin-top: 0; }

        .main-news__preview {
            font-size: 22px;
            line-height: 28px;
            height: auto;

            &:not(:last-child) {
                margin-bottom: 18px; } } }

    &__text {
        font-size: 15px;
        line-height: 22px; }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 32px;
        right: 32px;
        bottom: 25px; }

    &__content {
        opacity: 0; }

    &__content {
        height: 80%;
        overflow: hidden;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background: linear-gradient(180deg, rgba(35,43,55,0) 0%, rgba(35,43,55,1) 77%); } }

    &__bottom-text {
        font-size: 14px;
        line-height: 20px; }

    &__bottom-icon {
        background-color: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;

        svg {
            width: 16px;
            height: 16px; } }

    &__card {
        position: relative;
        display: block;
        background: #F2F2F2;
        margin-bottom: 16px;
        padding-bottom: 23px;
        overflow: hidden;

        @media #{$mobile-above} {
            height: calc(100% - 16px); }

        &:hover {

            .main-news__photo {

                &:after {
                    opacity: 1; }

                img {
                    transform: scale(1.1); } }

            @media #{$tablet-above} {
                .main-news__hover {
                    transform: translateY(0); }

                .main-news__content {
                    animation: showItems .6s ease-out forwards; } }

            .main-news__preview {
                color: $blue; }

            .main-news__category {
                color: #09101B; }

            .main-news__hover {
                .main-news__category {
                    color: #9399A4; }

                .main-news__preview {
                    color: inherit; } } } }

    &-col {

        &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
 } }            // padding-bottom: 16px

    &__mask {
        overflow: hidden; }

    &__photo {
        position: relative;
        height: 240px;
        line-height: 0;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(9, 16, 27, .2);
            opacity: 0;
            transition: all ease .3s;
            z-index: 1; }

        img {
            max-width: none;
            // width: 100%
            height: 100%;
            min-width: 100%;
            transition: transform ease .3s;
            transform: scale(1);
            will-change: transform;
            object-fit: cover;
            object-position: left top; } }

    &__category {
        margin-top: 28px;
        padding: 0 32px;
        font-size: 14px;
        line-height: 20px;
        color: #9399A4;
        transition: all ease .3s;

        span {
            position: relative;

            &:not(:last-child):after {
                content: "•";
                padding-left: 6px;
                padding-right: 2px; } } }

    &__preview {
        display: block;
        padding: 0 32px;
        margin-top: 10px;
        font-size: 22px;
        line-height: 32px;
        box-sizing: border-box;
        // height: 130px
        width: 100%;
        font-weight: 600;
        transition: all ease .3s; }

    .js-previewFull {
        display: none; } }

@keyframes showItems {
    0% {
        opacity: 0; }

    50% {
        opacity: 0; }

    100% {
        opacity: 1; } }

@media #{$large} {

    .main-news {
        padding-bottom: 113px;

        &__photo {
            height: 272px; } } }



@media #{$middle-only} {

    .main-news {

        &__photo {
            height: 160px; }

        &__preview {
            font-size: 16px;
            line-height: 24px; } } }

@media #{$tablet-only} {

    .main-news {
        margin-top: 112px;
        padding-bottom: 113px;

        .title {
            margin-bottom: 65px; }

        &-inside {
            // margin-top: 50px
            margin-top: 5px;

            .title {
                // margin-bottom: 75px
                margin-bottom: 18px;
                font-size: 74px;
                line-height: 112px; }

            .tabs__list {
                margin-bottom: 35px; }

            & + .subscribe {
                margin-top: -13px; } } } }

@media #{$mobile-above} {

    .echo-effect {

        .main-news__photo {
            background: url('../images/blog_3_hov.jpg') no-repeat left top;
            background-size: 100% 100%;

            @media #{$retina} {
                background: url('../images/blog_3_hov@2x.jpg') no-repeat left top;
                background-size: cover; }

            &:after {
                display: none; }

            img {
                transition: all ease .3s;
                transform: scale(1)!important; } }

        &:hover {
            background: #E9E9E9;

            .main-news__photo img {
                opacity: 0;
                visibility: hidden;
                transform: scale(1); } } } }

@media #{$mobile} {

    .main-news {
        margin-top: 50px;
        padding-bottom: 33px;

        &.main-news-inside {
            margin-top: 33px;

            .title {
                margin-bottom: 23px; } }

        .title {
            margin-bottom: 30px;
            font-size: 35px;
            line-height: 38px; }

        .tabs__list {
            margin-bottom: 24px; }

        &__card {
            margin-bottom: 8px;
            padding-bottom: 15px; }

        &__photo {
            height: 200px;

            @media (orientation: landscape) {
                height: 62.5vw; } }

        &__category {
            margin-top: 15px;
            padding: 0 16px; }

        &__preview {
            margin-top: 6px;
            padding: 0 16px;
            font-size: 19px;
            line-height: 24px;
            min-height: 96px; } } }
