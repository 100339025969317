.promo {

    &__content {
        position: relative; }

    &__line {
        font-size: 106px;
        line-height: 0;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: -25px;
        overflow: hidden; }

    &__mask {
        display: flex;
        align-items: center;
        height: 135px;
        transition: all ease-in-out 1s;
        transform: translateY(100%);
        will-change: auto;
        opacity: 0;

        &.animated {
          transform: translateY(0);
          opacity: 1; } }

    &__slider {
        margin: -13px 5px 0;
        width: 112px;
        height: 112px;
        border-radius: 50%;
        overflow: hidden; }

    .swiper-wrapper {
        transition: all cubic-bezier(.75, 0.35, 0, 1); }

    .swiper-slide {
        max-height: 112px;
        transition: all cubic-bezier(.75, 0.35, 0, 1);
        transform: translateY(0);
        z-index: 1;

        img {
            width: 100%; }

        &.stay {
            transform: translateY(100%);
            z-index: -1; }

        &.hide {
            opacity: 0; } }

    .promo__slide {
        line-height: 0; }

    &__paragraph {
        position: absolute;
        right: 14px;
        bottom: -7px;
        font-size: 24px;
        line-height: 32px;
        max-width: 568px;
        will-change: auto;
        opacity: 0;
        transform: translateY(10%);
        transition: all ease-in .6s;
        transition-delay: .3s;

        &.animated {
          opacity: 1;
          transform: translateY(0); } }

    &-nav {
        color: #fff;

        &__head {
            &:not(:last-child) {
                margin-bottom: 30px; }

            @media #{$mobile} {
                width: 37%; } }

        &__bottom {
            display: flex;
            padding-left: 11px;

            @media #{$mobile-above} {
                margin-top: auto; }

            @media #{$mobile} {
                width: 63%;
                padding-right: 0;

                .promo-nav__menu {
                    margin-top: 0; } } }

        &-row {
            display: flex; }

        &-col {
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
            overflow: hidden;
            transition: all ease .3s; }

        &__title {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
            text-transform: uppercase;

            &.size_big {
                font-weight: 500;
                letter-spacing: -0.01em;
                font-feature-settings: 'pnum' on, 'lnum' on, 'case' on;
                font-size: 32px;
                line-height: 37px;

                @media (min-width: 1300px) {
                    font-size: 78px;
                    line-height: 83px; }

                @media #{$tablet-above} {
                    @media (max-width: 1400px) {
                        font-size: 56px;
                        line-height: 64px; } }

                @media #{$mobile} {
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 20px; } } }

        &__menu {
            margin: 0 0 0 40px;

            @media #{$tablet-above} {
                @media (max-width: 1400px) {
                    margin-left: 30px; } }

            & + .promo-nav__link {
                margin-left: auto;
                margin-top: auto;

                @media #{$mobile-above} {
                    margin-bottom: 10px; } }

            li {
                font-size: 12px;
                line-height: 16px;
                opacity: 1;

                &:not(:last-child) {
                    margin-bottom: 8px; } } } }


    &-integrator {
        position: relative;
        display: block;
        background: #232B37;
        padding: 32px 128px 32px 40px; }

    &-agency {
        position: relative;
        display: block;
        background: $gradient;
        padding: 32px 128px 32px 40px; }

    &-integrator, &-agency {
        transition: all ease .3s;
        height: 100%;

        &:hover {

            .promo__more {
                background: $blue;

                .icon {
                    fill: #fff;
                    transform: rotate(0); } } } }

    &__more {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 28px;
        top: 28px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: #fff;

        &.size_big {
            @media #{$tablet-above} {
                width: 66px;
                height: 66px;

                @media (max-width: 1400px) {
                    width: 56px;
                    height: 56px; } }

            @media #{$tablet} {
                width: 44px;
                height: 44px; }

            @media #{$mobile} {
                width: 25px;
                height: 25px;

                .icon {
                    width: 10px;
                    height: 10px;
                    position: relative;
                    top: -2px; } } }

        .icon {
            width: 16px;
            height: 16px;
            fill: #09101B;
            transition: all ease .3s;
            transform: rotate(-45deg); } } }

.promo_box {
    min-height: 525px;
    padding: 60px 65px 48px 85px;
    display: block;

    @media #{$tablet} {
        min-height: 293px;
        padding: 60px 40px 68px 40px; }

    @media #{$tablet-above} {
        @media (max-width: 1400px) {
            min-height: 385px;
            padding: 45px 50px 63px 60px; } }

    @media #{$mobile} {
        min-height: 125px;
        padding: 22px 40px 48px 20px;

        .promo-nav__link {
            position: absolute;
            left: 20px;
            bottom: 25px; } }

    .promo-nav__menu li {
        font-size: 18px;
        line-height: 180%;
        font-family: 'Roboto', 'Proxima', sans-serif;

        @media #{$tablet-above} {
            @media (max-width: 1400px) {
                font-size: 15px; } }

        @media #{$tablet} {
            font-size: 13px; }

        @media #{$mobile} {
            font-size: 11px; }

        &:not(:last-child) {
            margin-bottom: 0; }

        a {
            font-size: inherit;
            line-height: inherit; } }

    .row {
        height: 100%; }

    .promo-nav__menu {
        margin-left: 0;

        @media #{$mobile-above} {
            &:not(:last-child) {
                width: calc(100% - 70px); } } }

    .promo__more {
        position: static; } }

@media #{$large} {

    .promo {

        > .container {
            padding-top: 34px; }

        &__line {
            font-size: 124px;
            line-height: 0;
            text-transform: uppercase;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: -25px; }

        &__mask {
            display: flex;
            align-items: center;
            height: 150px;
            overflow: hidden; }

        &__slider {
            width: 128px;
            height: 128px;
            overflow: hidden; }

        .swiper-slide {
            max-height: 128px; }

        &__paragraph {
            position: absolute;
            bottom: -6px;
            right: 0px;
            font-size: 24px;
            line-height: 32px;
            max-width: 583px; } } }

@media #{$extra-large} {

    .promo {

        &__content {
            padding-top: 150px; }

        &__line {
            font-size: 150px;
            line-height: 0;
            text-transform: uppercase;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: 0; }

        &__mask {
            display: flex;
            align-items: center;
            height: 160px;
            overflow: hidden; }

        &__slider {
            width: 134px;
            height: 134px;
            overflow: hidden; }

        .swiper-slide {
            max-height: 134px; }

        &__paragraph {
            position: absolute;
            bottom: 10px;
            right: 0px;
            font-size: 28px;
            line-height: 38px;
            max-width: 583px; }

        &-integrator {
            position: relative;
            display: block;
            background: #232B37;
            padding: 32px 128px 100px 40px; }

        &-agency {
            position: relative;
            display: block;
            background: $gradient;
            padding: 32px 128px 100px 40px; }

        &-nav {

            &__menu {

                li {
                    font-size: 16px;
                    line-height: 24px; } } } } }

@media #{$middle} {

    // .promo
    //
    //  // &__paragraph
    //  //     position: absolute
    //  //     right: 45px
    //  //     bottom: -35px
    //  //     font-size: 18px
    //  //     line-height: 28px
 }    //  //     max-width: 400px

@media #{$middle-only} {

    .promo {

        &__line {
            font-size: 75px;
            line-height: 0;
            text-transform: uppercase;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: -25px; }

        &__mask {
            display: flex;
            align-items: center;
            height: 100px;
            overflow: hidden; }

        &__slider {
            margin: 0 10px;
            width: 75px;
            height: 75px;
            overflow: hidden; }

        .swiper-slide {
            max-height: 128px; }

        &__paragraph {
            position: absolute;
            right: 10px;
            bottom: -5px;
            font-size: 16px;
            line-height: 19px;
            max-width: 340px; }

        &-nav {

            &__title {
                font-size: 20px; } } } }

@media #{$tablet-above} {

    .promo {

        > .container {
            padding-top: 45px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100vh - 184px); }

        &-nav {
            padding-top: 45px; }

        &-nav-col {

            &:hover {
                margin-top: -32px;

                .promo-integrator,
                .promo-agency {
                    padding-bottom: 64px; } } } } }

@media screen and(min-width: 1201px)and(max-width: 1400px) {

    .promo {

        > .container {
            padding-top: 0;
            min-height: 480px; }

        &__line {
            font-size: 87px;
            line-height: 0;
            text-transform: uppercase;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: -20px; }

        &__mask {
            display: flex;
            align-items: center;
            height: 100px;
            overflow: hidden; }

        &__slider {
            margin: 0 10px;
            width: 75px;
            height: 75px;
            overflow: hidden; }

        .swiper-slide {
            max-height: 128px; }

        &__paragraph {
            position: absolute;
            right: 120px;
            bottom: -5px;
            font-size: 19px;
            line-height: 24px;
            max-width: 370px;

            &__title {
                font-size: 20px; } }

        &-integrator, &-agency {
            padding-right: 90px; } } }

@media #{$tablet-only} {

    .promo {

        > .container {
            padding-top: 22px; }

        &__line {
            // font-size: 60px
            font-size: 7.815vw;
            margin-bottom: -9px; }

        &__mask {
            height: 80px; }

        &__slider {
            flex: 0 0 80px;
            width: 80px;
            height: 80px;
            margin: 0 2px; }

        .swiper-slide {
            max-height: 80px; }

        &__paragraph {
            position: relative;
            right: auto;
            bottom: auto;
            max-width: 580px;
            margin-top: 196px; }

        &-nav {
            margin-top: 64px;

            &__title {
                font-size: 32px; }

            &__menu {
                margin: 16px 0 0; } }

        &-integrator,
        &-agency {
            padding: 32px; } } }

@media #{$mobile} {

    .promo {

        > .container {
            padding-top: 34px; }

        &__line {
            font-size: 27px;
            margin-bottom: -18px; }

        &__mask {
            height: 50px; }

        &__slider {
            flex: 0 0 38px;
            // width: 36px
            height: 36px;
            margin: 0 4px;
            width: 38px;
            text-align: center; }

        .swiper-slide {
            max-height: 36px;
            width: 100%;

            img {
                width: 36px; } }

        &__paragraph {
            position: relative;
            right: auto;
            bottom: auto;
            max-width: 340px;
            margin-top: 98px;
            font-size: 19px;
            line-height: 24px; }

        &-nav {
            margin: 32px -8px 0;

            &-row {
                flex-wrap: wrap; }

            &-col {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%; }

            &__title {
                font-size: 24px;
                line-height: 32px; }

            &__menu {
                margin: 16px 0 0; } }

        &-integrator,
        &-agency {
            padding: 24px 15px; }

        &__more {
            top: 22px;
            right: 15px;
            width: 40px;
            height: 40px; } } }

@media #{$mobile-small} {

    .promo {

        &__line {
            font-size: 23px;
            margin-bottom: -18px; }

        &__mask {
            height: 50px; }

        &__slider {
            flex: 0 0 38px;
            width: 38px;
            height: 36px;
            margin: 0 4px;
            text-align: center; }

        .swiper-slide {
            max-height: 36px; } } }
