html,
body {
	height: 100%; }

body {
	position: relative;
	font-size: 24px;
	line-height: 1.45;
	color: #09101B;
	background: #fff;
	-webkit-font-smoothing: antialiased;
	font-family: 'Proxima';
	overflow-x: hidden;
	padding-top: 184px;

	@media (max-width: 1200px) {
		padding-top: 144px; }

	@media #{$mobile} {
		padding-top: 96px; } }

*, :before, :after {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }

* {
	-webkit-locale: auto; }

svg, img {
	max-width: 100%; }

input,
textarea,
button,
select {
	border: none;
	outline: none;
	border-radius: 0;
	font-size: 16px;
	font-family: 'Proxima'; }

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
button {
	-webkit-appearance: none; }

input {
	width: 100%;
	height: 56px;
	border: #D3DDE5 1px solid;
	border-radius: 8px;
	font-size: 16px;
	color: #0C0D1F;
	box-sizing: border-box;
	padding: 0 24px;
	font-family: 'Proxima'; }

textarea {
	width: 100%;
	min-height: 110px;
	max-height: 200px;
	border: #D3DDE5 1px solid;
	border-radius: 8px;
	font-size: 16px;
	color: #0C0D1F;
	box-sizing: border-box;
	padding: 18px 24px;
	resize: vertical;
	font-family: 'Proxima'; }

input, textarea {

	&:focus {
		border-color: #1C455F; } }


fieldset {
	border: none; }

h1, h2, h3, h4, h5, h6 {
	font-family: inherit;
	font-weight: normal;
	color: inherit; }

a {
	color: inherit;
	text-decoration: none;
	transition: all ease .3s;
	will-change: auto;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; } }

li {
	list-style: none; }

.visually-hidden {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	width: 1px !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important; }

.hidden-tab {
	@media #{$tablet} {
		display: none !important; } }

.hidden-xs {
	@media #{$mobile} {
		display: none !important; } }

.visible-xs {
	display: none !important;

	@media #{$mobile} {
		display: block !important; } }

.split-parent {
	overflow: hidden;
	backface-visibility: hidden; }

.title {
	text-align: center;
	font-size: 108px;
	line-height: 112px;
	text-transform: uppercase;
	font-weight: 500;
	margin: 0 0 80px;

	&.size_middle {
		font-size: 56px;
		line-height: 64px;
		margin-bottom: 48px;

		@media #{$tablet} {
			font-size: 42px;
			line-height: 47px;
			letter-spacing: 0.01em; }

		@media #{$mobile} {
			font-size: 18px;
			line-height: 23px;
			letter-spacing: -0.01em; } }

	@media #{$tablet-above} {
		@media (max-width: 1440px) {
			font-size: 86px;
			line-height: 90px; } }

	@media #{$large} {
		font-size: 124px;
		line-height: 128px; }

	@media #{$middle-only} {
		font-size: 75px;
		line-height: 80px; }

	@media #{$tablet-only} {
		font-size: 60px;
		line-height: 72px;

		&.title_size_middle {
			font-size: 74px;
			line-height: 112px; } }

	@media #{$mobile} {
		font-size: 24px;
		line-height: 32px;

		&.title_size_middle {
			font-size: 35px;
			line-height: 38px; } }

	&.size_small {
		font-size: 52px;
		line-height: 68px;

		@media #{$tablet-above} {
			letter-spacing: -0.01em; }

		@media #{$tablet} {
			font-size: 24px;
			line-height: 32px;
			font-weight: bold; }

		@media #{$mobile} {
			font-size: 20px;
			line-height: 26px; } }

	&.title_medium {
		font-size: 98px;
		line-height: 112px;
		letter-spacing: -0.01em;

		span {
			color: #EE502C; }

		.color_green {
			color: #469357; } } }

.subtitle {
	&.subtitle_small {
		font-weight: 500;
		font-size: 68px;
		line-height: 85px;
		text-transform: uppercase;

		@media #{$tablet} {
			font-size: 52px;
			line-height: 68px;

			br {
				display: none; } }

		@media #{$mobile} {
			font-size: 21px;
			line-height: 28px; } } }

@media (max-width: 1199px) {
	.title.title_medium {
		font-size: 64px;
		line-height: 72px; } }

@media #{$mobile} {
	.title.title_medium {
		font-size: 26px;
		line-height: 28px; } }

.but {
	background: none;
	border: none;
	width: 100%;
	max-width: 300px;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		line-height: 1;
		font-weight: 500;
		height: 40px;
		padding: 0 15px;
		border-radius: 20px;
		color: #fff;
		transition: all ease .3s;
		cursor: pointer;

		@media #{$mobile} {
			height: 48px;
			font-size: 14px;
			font-weight: 500;

			&.visible-xs {
				display: flex !important; } } }

	&.size_middle {
		@media #{$tablet-above} {
			span {
				height: 60px;
				border-radius: 32px;
				letter-spacing: 0.04em; } } }

	&.size_big {
		display: inline-block;

		span {
			@media #{$tablet-above} {
				text-transform: uppercase;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				border-radius: 40px;
				height: 56px;
				font-weight: bold; }

			@media #{$mobile} {
				font-size: 13px;
				line-height: 20px;
				font-weight: 600;
				letter-spacing: 0px;
				height: 30px; } } }

	&_green {
		span {
			background: $green; }

		@media #{$tablet-above} {
			&:hover {

				span {
					background: #1066A5; } } } }

	&_white {
		span {
			background: #FFFFFF;
			color: #EE502C;
			border: 1px solid #fff; }

		@media #{$tablet-above} {
			&:hover {
				span {
					background: transparent;
					color: #fff !important; } } } }

	&_white_border {
		span {
			color: #fff;
			border: 1px solid #fff; } }

	&_white_green {
		span {
			background: #FFFFFF;
			color: #53A138;
			border: 1px solid #fff; }

		@media #{$tablet-above} {
			&:hover {
				span {
					background: transparent;
					color: #fff !important; } } } }

	&_white_blue {
		span {
			background: #FFFFFF;
			color: #235CD0;
			border: 1px solid #fff; }

		@media #{$tablet-above} {
			&:hover {
				span {
					background: transparent;
					color: #fff !important; } } } }

	&_white_black {
		span {
			background: #FFFFFF;
			color: #000000;
			border: 1px solid #fff; }

		@media #{$tablet-above} {
			&:hover {
				span {
					background: transparent;
					color: #fff !important; } } } }

	&_green_border {
		span {
			color: #38A14C;
			border: 1px solid #38A14C; } }

	&.black_border {
		span {
			color: #09101B;
			border: 1px solid #09101B; }

		@media #{$tablet-above} {
			&:hover {
				span {
					background-color: #09101B;
					color: #fff; } } } }

	&_blue {
		span {
			background: #232B37; }

		@media #{$tablet-above} {
			&:hover {

				span {
					background: $green_hover; } } } }

	&:disabled {
		pointer-events: none;

		span {
			background-color: rgba(147, 153, 164, .4);
			color: #fff; } } }

.color_green {
	color: #469357; }

.color_green_gradient {
	color: transparent !important;
	background-image: linear-gradient(90deg, rgba(40, 161, 206, 1) 0%, rgba(56, 168, 94, 1) 100%);
	-webkit-background-clip: text; }

.mob {
	@media #{$mobile-above} {
		display: none !important; } }

.tablet {
	@media #{$tablet-above} {
		display: none !important; } }

.pc-tablet {
	@media #{$tablet} {
		display: none !important; } }

.pc {
	@media #{$mobile} {
		display: none !important; } }

.swiper-container {
	width: 100%;
	height: 100%; }

.to-all {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 240px;
	height: 240px;
	border-radius: 50%;
	background: #232B37;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	margin-left: auto;
	overflow: hidden;
	transition: all ease .5s;
	z-index: 0;

	&:after {
		content: "";
		display: block;
		position: absolute;
		left: calc(50% - 10px);
		top: calc(50% - 10px);
		width: 20px;
		height: 20px;
		background: $blue;
		border-radius: 50%;
		z-index: 0;
		transform: scale(0);
		transition: all ease-in-out .5s; }

	&:hover {
		background: $blue;

		&:after {
			transform: scale(20); } }

	&_centered {
		margin: 64px auto 0; }


	span {
		position: relative;
		z-index: 1; }

	@media #{$large} {
		width: 272px;
		height: 272px; }

	@media #{$middle-only} {
		width: 160px;
		height: 160px;

		span {
			font-size: 14px; } }

	@media #{$mobile} {
		margin: 25px auto 0;
		width: 146px;
		height: 146px;
		font-size: 12px;
		line-height: 24px; } }

.container-small {
	max-width: 944px;
	margin: 0 auto;

	@media #{$tablet} {
		max-width: 100%; }

	@media #{$tablet-only} {
		padding: 0 58px;

		.hr {
			margin-left: -58px;
			margin-right: -58px; } } }

.hr {
	border-top: none;
	border-bottom: 1px solid #E9E9E9;
	margin-bottom: 108px;

	@media #{$tablet} {
		margin-bottom: 60px; }

	@media #{$mobile} {
		margin-bottom: 40px; } }

.link {
	color: #38A14C;

	@media #{$tablet-above} {
		&:hover {
			opacity: .8; } } }

.link-icon {
	padding-left: 10px;

	@media #{$mobile} {
		padding-left: 7px; } }

.section-title {
	font-size: 56px;
	line-height: 64px;
	text-transform: uppercase;
	font-weight: 600;

	@media #{$tablet} {
		font-size: 32px;
		line-height: 40px;
		font-weight: bold; }

	@media #{$mobile} {
		font-size: 16px;
		line-height: 22px;
		padding: 0 10px; } }

.adaptive {
	a:not(.relevant-card):not(.faq-tabs__elem) {
		&:hover {
			color: inherit !important; } } }

.text_black {
	color: #000; }

.export-from-tildа-wrapper {
	position: relative;
	z-index: 1; }

body.tilda-page *, body.tilda-page :before, body.tilda-page :after {
	box-sizing: border-box !important; }

body.tilda-page .export-from-tildа-wrapper *, body.tilda-page .export-from-tildа-wrapper *:before, body.tilda-page .export-from-tildа-wrapper *:after {
	box-sizing: content-box !important; }

.overflow-hidden {
	overflow: hidden; }

.services_web-development-page {
  .header,
  .mobile-nav,
  .footer,
  .popup {
    box-sizing: border-box !important;

    *, *::before, *::after {
      box-sizing: border-box !important; } } }


