.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 1000;
  transition: .3s ease-out;

  &.is_hide {
    opacity: 0; } }
