$extra-large: 'screen and(min-width: 2500px)';
$large: 'screen and(min-width: 1900px)';
$laptop: 'screen and(max-width: 1440px)';
$tablet: 'screen and(max-width: 1024px)';
$middle-only: 'screen and(min-width: 1025px)and(max-width: 1280px)';
$middle: 'screen and(min-width: 1025px)and(max-width: 1599px)';
$middle-above: 'screen and(min-width: 1600px)';
$tablet-only: 'screen and(min-width: 768px)and(max-width: 1024px)';
$tablet-landscape: 'screen and(min-width: 768px)and(max-width: 1024px)and(orientation: landscape)';
$tablet-portrait: 'screen and(min-width: 768px)and(max-width: 1024px)and(orientation: portrait)';
$tablet-above: 'screen and(min-width: 1025px)';
$mobile: 'screen and(max-width: 767px)';
$mobile-above: 'screen and(min-width: 768px)';
$mobile-small: 'screen and(max-width: 360px)';
$retina: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
$ie11: "all and (-ms-high-contrast:none)";

/* colors*/
$green: #38A14C;
$green_hover: #019681;
$blue: #28A1CE;
$black: #09101B;
$gray: #F2F2F2;
$medium: #4D5460;

$color-text-white: #ffffff;
$color-text-white-60: rgba(255, 255, 255, 0.6);
$color-text-blue: #235CD0;

$gradient: radial-gradient(97.12% 256.02% at 100% 0%, #0F65A4 0%, #019681 41.68%, #38A14C 84.56%);
