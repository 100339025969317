.company {
    padding-top: 65px;

    .title {
        text-align: left;
        margin-bottom: 112px;
        letter-spacing: -2px; }

    &-content {
        max-width: 944px;
        margin: 0 auto;

        @media #{$tablet-above} {
            @media (max-width: 1440px) {
                max-width: 820px; } } }

    &__paragraph {
        font-size: 44px;
        line-height: 48px;
        font-weight: 500;
        margin-top: 50px;

        @media #{$tablet-above} {
            margin-right: -10px;

            @media (max-width: 1440px) {
                font-size: 28px;
                line-height: 34px;
                margin-top: 35px; } }

        @media (min-width: 1300px) {
            @media (max-width: 1440px) {
                padding-right: 160px; } }

        & + .company__head-text {
            margin-top: 48px;

            @media #{$tablet} {
                margin-top: 34px; }

            @media #{$mobile} {
                margin-top: 13px; } } }

    &__head-text {
        font-size: 24px;
        line-height: 32px;

        @media #{$tablet} {
            font-size: 14px;
            line-height: 150%; }

        @media #{$mobile} {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em; } }

    &-cards {
        margin-top: 47px;

        &-col {
            margin-top: 16px; }

        &__item {
            background: #F2F2F2;
            padding: 23px 32px; }

        &__title {
            color: $green;
            font-size: 72px;
            line-height: 72px;
            font-weight: 500; }

        &__text {
            color: #4D5460;
            font-size: 14px;
            line-height: 20px;
            min-height: 50px;
            max-width: 300px; }

        &__head {
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 48px;

                @media #{$mobile} {
                    margin-bottom: 24px; } } } }

    &-block {
        margin-top: 64px;

        &:not(.not_border) {
            padding-top: 64px;
            border-top: #E9E9E9 1px solid;

            @media #{$tablet} {
                padding-top: 44px; }

            @media #{$mobile} {
                padding-top: 34px; } }

        &__title {
            font-size: 44px;
            line-height: 48px;
            font-weight: 500; }

        p {
            font-size: 24px;
            line-height: 32px;
            margin-top: 48px; } }

    &-advantages {

        &__item {
            margin-top: 45px; }

        &__title {
            position: relative;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            font-weight: bold;
            text-transform: uppercase;
            padding-left: 120px;
            padding-bottom: 24px;

            b,
            span {
                position: absolute;
                top: 0;
                left: 0;
                font-weight: inherit; } } }

    &-best {
        margin-top: 0;
        padding-bottom: 182px; } }

.company {
    & + .best {
        margin-top: 160px;

        @media #{$tablet} {
            margin-top: 88px; }

        @media #{$mobile} {
            margin-top: 46px; } } }

.company-page {
    .company-best {

        .title {
            @media #{$tablet-above} {
                font-size: 32px;
                line-height: 40px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center; }

            br {
                display: none;

                @media #{$tablet-only} {
                    display: block; } } } } }


@media #{$tablet-only} {

    .company {
        padding-top: 5px;

        .title {
            font-size: 74px;
            line-height: 112px;
            margin-bottom: 50px;
            letter-spacing: -1px; }

        &-content {
            padding: 0 65px; }

        &__paragraph {
            font-size: 26px;
            line-height: 32px; }

        &-cards {
            margin-top: 27px;

            &__title {
                font-size: 42px;
                line-height: 80px; }

            &__item {
                padding: 20px 28px; } }

        &-block {
            padding-top: 45px;
            margin-top: 45px;

            &__title {
                font-size: 26px;
                line-height: 32px; }

            p {
                font-size: 14px;
                line-height: 1.5;
                margin-top: 35px; } }

        &-advantages {

            &__item {
                margin-top: 32px; }

            &__title {
                padding-left: 75px;
                letter-spacing: -1px;
                padding-bottom: 15px; }

            &__text {
                font-size: 14px;
                line-height: 20px; } }


        &-best {
            padding-bottom: 80px;

            .best__icon {
                margin-bottom: 32px; } } }

    .company-page {

        .ahead {
            margin-top: 98px; } }

    .company-best {

        padding-top: 85px;

        .title {
            font-size: 42px;
            line-height: 50px; } } }


@media #{$mobile} {

    .company {
        padding-top: 30px;

        .title {
            font-size: 35px;
            line-height: 38px;
            margin-bottom: 35px;
            letter-spacing: 0; }

        &__paragraph {
            font-size: 14px;
            line-height: 18px;
            margin-top: auto;
            margin-bottom: 10px; }

        &-cards {
            margin-top: 20px;

            &-col {
                margin-top: 10px; }

            &__title {
                font-size: 34px;
                line-height: 40px; }

            &__text {
                font-size: 12px;
                line-height: 16px;
                min-height: 30px; }

            &__item {
                padding: 18px 25px; } }

        &-block {
            margin-top: 40px;
            padding-top: 30px;

            &__title {
                font-size: 18px;
                line-height: 18px; }

            p {
                font-size: 12px;
                line-height: 16px;
                margin-top: 13px;
                letter-spacing: 0.3px; } }

        &-advantages {

            &__item {
                margin-top: 23px; }

            &__title {
                font-size: 11px;
                line-height: 16px;
                padding-left: 32px;
                letter-spacing: 0px;
                padding-bottom: 15px;
                padding-bottom: 0; }

            &__text {
                font-size: 12px;
                line-height: 16px;
                margin-top: 10px; } }


        h3.company-block__title {
            font-size: 18px;
            margin-bottom: 20px; }

        &-best {
            padding-bottom: 48px;

            .best__icon {
                margin-bottom: 10px; } } } }
