.contact-map {
	height: 456px;
	background: #f1f1f1;
	margin-top: 103px;
	margin-bottom: 60px;

	&__area {
		width: 100%;
		height: 100%;
		outline: none; }

	@media #{$tablet} {
		margin-top: 0;
		margin-bottom: 40px;
		height: 314px; }

	@media #{$mobile} {
		height: 363px;
		margin-bottom: 10px; } }

.contact-elem {
	padding: 68px 0px 60px;
	font-size: 24px;
	line-height: 32px;
	display: flex;

	&:not(:last-child) {
		border-bottom: 1px solid #E9E9E9; }

	&:last-child {
		padding-bottom: 0; }

	&__title {
		width: 32.333%;
		font-weight: 500;
		font-size: 44px;
		line-height: 1; }

	&__location {
		width: calc(67.666% - 320px);
		display: flex; }

	&__clock {
		width: 52px;
		height: 52px;
		display: flex;
		align-content: center;
		justify-content: center;
		border: 2px solid #9399A4;
		border-radius: 50%; }

	&__address {
		width: calc(100% - 52px);
		color: #9399A4;
		padding-left: 40px;

		b {
			color: #09101B;
			font-weight: 500;
			display: block; } }

	&__street {
		display: block;
		max-width: 425px; }

	&__schedule {
		font-weight: normal;
		color: #9399A4; }

	&__info {
		width: 320px;
		font-weight: 500; }

	@media #{$tablet-only} {
		&__clock {
			display: block;
			border-width: 1px;

			.clock {
				margin: -7px 0 0 -8px;
				transform: scale(.7); } } }

	@media #{$tablet} {
		font-size: 14px;
		line-height: 20px;
		padding: 35px 0;

		&__title {
			width: 34%;
			font-size: 24px; }

		&__location {
			width: calc(66% - 180px); }

		&__clock {
			width: 35px;
			height: 35px; }

		&__address {
			width: calc(100% - 35px);
			color: rgba(0, 0, 0, 0.7);
			padding-left: 22px;

			b {
				margin-bottom: 2px; } }

		&__street {
			max-width: 170px; }

		&__info {
			width: 180px;
			font-weight: 400; }

		&__mail {
			margin-top: 5px; } }

	@media #{$mobile} {
		padding: 25px 0;
		position: relative;
		display: block;

		&__title {
			width: 100%;
			font-size: 20px;
			margin: 0 0 10px; }

		&__location,
		&__info {
			width: 100%; }

		&__location {
			margin-bottom: 10px; }

		&__address {
			width: 100%;
			padding-left: 0; }

		&__street {
			max-width: 250px; }

		&__clock {
			width: 32px;
			height: 32px;
			position: absolute;
			top: 25px;
			right: 0;
			border-width: 1px;

			.clock {
				margin-top: -1px;
				margin-left: -1px; } } } }

.contact-messenger {
	font-weight: normal;
	color: #9399A4;

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 4px;
		height: 4px;
		margin: 0 11px;
		background: #9399A4;
		border-radius: 50%; }

	@media #{$tablet} {
		margin-left: 10px;

		&:before {
			display: none; } } }

.contact-mail {
	color: #38A14C;

	@media #{$tablet} {
		color: #77829E; } }

.contact {

	&-phone,
	&-mail {

		&:hover {
			color: #019681; } }

	&-route {

		.icon {
			width: 16px;
			height: 16px;
			fill: none;
			margin-bottom: -1px;

			@media #{$tablet} {
				width: 10px;
				height: 10px; } }

		&__link {
			color: #019681; } } }
